import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./InitiateOfferTable.scss";
import { InitiateOfferModel } from "./Model";
import DataTable from "react-data-table-component";
import { getAPI } from "../../../services/CepApiServices";
import { TableColumn } from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { McButton, McIcon, McNotification } from "@maersk-global/mds-react-wrapper";
import { getUserRole } from "../../../utils/token";
import { StatusList } from "../../../utils/StatusList";
import WriteAccessModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessModal";
import ManageWriteAccessRequestsModal from "../../../CharterRequest/RequestPages/ManageWriteAccessRequestsModal/ManageWriteAccessRequestsModal";
interface IPROPS {
  userRole?: any;
}

const InitiateOfferTable = ({ userRole }: IPROPS) => {
  const [tableData, setTableData] = useState([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const [showNotificationVO, setShowNotificationVO] = useState(true);
  const [manageWriteRequestModal, setManageWriteRequestModal] = useState(false);
  const [accessRequestsData, setAccessRequestsData] = useState<any>();

  const history = useNavigate();
  const getInitiateOfferData = async () => {
    setIsTableLoader(true);
    await getAPI(`InitialOffer/GetSentOffers`).then((response) => {
      setTableData(response);
    });
    setIsTableLoader(false);
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      if (userRole === "VesselOwner") {
        getInitiateOfferData();
      } else if (userRole === "OwnerRepresentative") {
        getInitiateOfferData();
        getNotificationForVOR(userRole);
      }
    };
    getUserRoleAsync();
  }, []);
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const handleInitiateOffer = (row: any) => {
    history(`/InitiateOfferDetails?id=${row.indicationRequestId}`);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const pageReload = () => {
    getInitiateOfferData();
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const handleWriteAssigneeFun = (value: any) => {
    SetIsWriteAccess(true);
    setRowData(value);
  };
  const openWriteAccessRequests = () => {
    setManageWriteRequestModal(true);
  }

  const onWriteAccessRequestsModalClose = () => {
    setManageWriteRequestModal(false);
  }

  const getNotificationForVOR = async (role) => {
    await getAPI(`IndicationRequest/GetVORWriteAccessRequests`).then(
      (response) => {
        if (response.length >= 1) {
          role === "OwnerRepresentative" ? setShowNotificationVO(true):setShowNotificationVO(false);
          setAccessRequestsData(response);
        }
        else {
          setShowNotificationVO(false);
        }

      }
    );
  }
  const setGiveAccessData = () => {

  }
  const handleAssignee = (row: any) => {
    if (
      row?.statusId != 4 &&
      row?.statusId != 3 &&
      row?.statusId != 7 &&
      row.accessType == "Write"
    ) {
      return (
        <span
          className="manage-access"
          data-testid="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const columns: TableColumn<InitiateOfferModel>[] = [
    {
      name: "Status",
      selector: (row) => (row.statusName ? row.statusName : ""),
      cell: (row) => StatusList(row.statusId, row.statusName),
      sortable: true,
      width: "9%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row.requestName !== null ? row.requestName.toLowerCase() : "",
      cell: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Vessel Name",
      selector: (row) => (row.vesselName ? row.vesselName.toLowerCase() : ""),
      cell: (row) => (row.vesselName ? row.vesselName : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo ? row.imo : ""),
      sortable: true,
      width: "7%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row.charteringManager ? row.charteringManager.toLowerCase() : "",
      cell: (row) => (row.charteringManager ? row.charteringManager : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "CM Representative",
      selector: (row) =>
        row.charteringManagerRepresentative ? row.charteringManagerRepresentative.toLowerCase() : "",
      cell: (row) => (row.charteringManagerRepresentative ? row.charteringManagerRepresentative : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        row.vesselOwner ? row.vesselOwner.toLowerCase() : "",
      cell: (row) => (row.vesselOwner ? row.vesselOwner : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "VO Representative",
      selector: (row) =>
        row.vesselOwnerRepresentative ? row.vesselOwnerRepresentative.toLowerCase() : "",
      cell: (row) => (row.vesselOwnerRepresentative ? row.vesselOwnerRepresentative : ""),
      sortable: true,
      width: "11%",
    },
    // {
    //   name: "Access Type",
    //   selector: (row) => (row.accessType ? row.accessType : ""),
    //   cell: (row) => accessTypeSetter(row.accessType),
    //   sortable: true,
    //   width: "11%",
    // },
    {
      name: "Access",
      cell: (row) => handleAssignee(row),
      width: "7%",
    },
    {
      name: "Offer Type",
      selector: (row) => (row.offerType ? row.offerType.toLowerCase() : ""),
      cell: (row) => (row.offerType ? row.offerType : ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "",
      cell: (row) => (
        <McButton
          data-testid="view-button"
          label="View"
          onclick={(e) => handleInitiateOffer(row)}
          fit="small"
        ></McButton>
      ),
    },
  ];
  return (
    <>
      {showNotificationVO && (<div className="representative-notification">
        <McNotification heading="Write access requested"
          body="Check the requests and manage access" onClick={openWriteAccessRequests}></McNotification>
      </div>)}
    <div className="initialOfferPage">
      <div className="ir-table-main table-margin2">
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={isTableLoader}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        />
        <WriteAccessModal
          isModalOpen={isWriteAccess}
          onCloseFunc={closeFunc}
          rowData={rowData}
          isCm={false}
          reloadPage={pageReload}
        />
        <ManageWriteAccessRequestsModal isModalOpen={manageWriteRequestModal}
          onModalClose={onWriteAccessRequestsModalClose}
          accessRequestsData={accessRequestsData}
          userRole={userRole}
          setGiveAccessData={setGiveAccessData}
        />
      </div>
    </div>
    </>
  );
};

export default InitiateOfferTable;
