import { useEffect, useState } from "react";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import InitiateOfferTable from "./NegotiationOfferPages/InitiateOfferTable/InitiateOfferTable";
import InitiateOfferTableVO from "./NegotiationOfferPages/InitiateOfferTable/InitiateOfferTableVO";
import FilterComponent from "../components/FilterComponent/FilterComponent";
import "./NegotiationList.scss";
import { getUserRole } from "../utils/token";
const NegotiationList = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [userRole, setUserRole] = useState<any>("");
  const [dataObj, setDataObj] = useState<any>();
  const onFilterClick = () => {
    setIsFilter(true);
  };
  const clearFilterFunc = () => {
    setDataObj({});
  };
  const filterDataFunc = (obj: any) => {
    const formData = {
      statusID: obj?.statusField ? obj?.statusField : null,
      requestItem : obj?.requestItems ? obj?.requestItems.map((item) => item.label) : null,
    };
    setDataObj(formData);
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRole(userRole);
    };
    getUserRoleAsync();
  }, []);

  return (
    <div className="negotiation-main-table">
      <div className="container-fluid">
        <div className="header-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Negotiation</h5>
            </div>
            {(userRole == "CharteringManager" || userRole == "charteringManagerRepresentative") && (
              <div className="col-md-2 filter-buttons">
                <button className="clear-button" data-testid="clear-filter-button" onClick={clearFilterFunc}>Clear</button>
                <button
                  onClick={onFilterClick}
                  data-testid="apply-filter"
                  className="negotiation-filter-button"
                >
                  <span>
                    <McIcon icon="sliders-horizontal"></McIcon>
                  </span>
                  <span>Filter</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="table-section">
          {(userRole == "CharteringManager" || userRole == "charteringManagerRepresentative") ? (
            <InitiateOfferTable  filterData={dataObj} />
          ) : (
            <InitiateOfferTableVO />
          )}
        </div>
        <div className="negotiation-filter-section">
          {isFilter && (
            <FilterComponent
              filterFor={"Negotiation"}
              onDataFunc={filterDataFunc}
              closeFilterFunc={() => setIsFilter(false)}
              clearFilter={clearFilterFunc}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NegotiationList;
