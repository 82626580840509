import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  onModalOpen?: boolean;
  setConfirmAccess?: () => void;
  onModalClose?: any;
}

const RequestWriteAccessModal = ({
  onModalOpen,
  setConfirmAccess,
  onModalClose,
}: IPROPS) => {
  const onConfirmClick = () => {
    onModalClose("submit", true);
  };
  const closeFunc = () => {
    onModalClose("cancel", false);
  };

  return (
    <div>
      <McModal
        width="680px"
        height="280px"
        open={onModalOpen}
        heading="Request Write Access"
      >
        <p>Once your write access is approved, the assignee representative will have read access only.</p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          click={closeFunc}
          dialogAction="cancel"
          role="cancel-charter-data"
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={onConfirmClick}
          dialogAction="ok"
          role="delete-charter-data"
        >
          Confirm request
        </McButton>
      </McModal>
    </div>
  );
};

export default RequestWriteAccessModal;
