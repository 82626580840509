import { McModal, McButton } from "@maersk-global/mds-react-wrapper";

interface IPROPS {
  onDeleteOpen?: boolean;
  deleteRepId?: any;
  onSubmit: (id: any) => void;
}

const RemoveRepresentativeModal = ({
  onDeleteOpen,
  deleteRepId,
  onSubmit
}: IPROPS) => {
  
  const onDeleteClick = () => {
    onSubmit(deleteRepId);
  };

  return (
    <div>
      <McModal
        width="680px"
        height="280px"
        open={onDeleteOpen}
        heading="Remove Representative"
      >
        <p>Are you sure you want to <b>remove this representative?</b></p>
        <McButton
          slot="secondaryAction"
          variant="secondary"
          // click={closeDeleteFunc}
          dialogAction="cancel"
          role="cancel-charter-data"
        >
          Cancel
        </McButton>
        <McButton
          slot="primaryAction"
          variant="primary"
          click={onDeleteClick}
          dialogAction="ok"
          role="delete-charter-data"
        >
          Remove
        </McButton>
      </McModal>
    </div>
  );
};

export default RemoveRepresentativeModal;
