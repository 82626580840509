import { McModal, McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { McTab, McTabBar } from "@maersk-global/mds-react-wrapper";
import { getAPI, postAPI } from "../../../services/CepApiServices";
import { useState } from "react";

interface IPROPS {
    isModalOpen?: boolean;
    onCloseFunc?: any;
    infoData?: any;
    individualAccessRequestsData?: any;
    requestWriteAccessModal?: any;
    disableRequestWriteAccessBtn?:any;
}

const WriteAccessNewModal = ({ isModalOpen, onCloseFunc, infoData, individualAccessRequestsData, requestWriteAccessModal, disableRequestWriteAccessBtn }: IPROPS) => {
    const [tab, setTab] = useState(0);
    const [accessRequestsData, setAccessRequestsData] = useState<any>();
    const commentValue = infoData?.comment;
    const closeFilter = () => {
        onCloseFunc();
    }

    const changeHandler = (item: any) => {
        setTab(item.detail);
        if (item.detail === 1) {
            // getAccessRequestsData();
        }
    }
    const requestWriteAcess = () => {
        requestWriteAccessModal(true);
    }
    return (
        <McModal
            height="500px"
            width="1000px"
            className="broker-info-modal"
            heading={`Manage Accesss`}
            open={isModalOpen}
            backdropcloseactiondisabled
        >
            <div
                className="closeIcon-modal"
                data-testid="close"
                onClick={closeFilter}
            >
                <McIcon icon="times"></McIcon>
            </div>

            <div className="tab-section">
                <div className="request-write-access-btn">
                    <McButton onClick={requestWriteAcess} variant="secondary" type="button" fit="medium" disabled={disableRequestWriteAccessBtn}>Request Write Access</McButton>
                </div>
                <McTabBar
                    currentindex={tab}
                    change={changeHandler}
                    variant="default"
                >
                    <McTab
                        className="custom-height"
                        slot="tab"
                        label="Add Representative"
                        data-testid="vessel-details-tab"
                    ></McTab>
                    <div className="tab-row tab-section" slot="panel">

                        <div className="broker-data">
                            <div className="broker-data-io details-section">
                                {infoData?.cmrAssignmentUserDetails?.map((data: any) => {
                                    return (
                                        <div className="row details-row">
                                            <div className="col-md-4">
                                                <div className="header space-btw">Name</div>
                                                <div className="value">{data.name}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="header">E-mail</div>
                                                <div className="value">{data.email}</div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="header">Access type</div>
                                                <div className="value">
                                                    {data.accessType}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>


                            <div className="broker-data-io details-section">
                                <div className="col-md-11 comments-section">
                                    <label className="comment-label">Comments</label>
                                    <textarea
                                        data-testid="cm-comments"
                                        className="broker-details-commentArea"
                                        defaultValue={
                                            commentValue !== null ? commentValue : ""
                                        }
                                        placeholder="comments"
                                        rows={3}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <McTab
                        slot="tab"
                        label="Access requests"
                        data-testid="commercial-details-tab"
                    ></McTab>
                    <div className="tab-row" slot="panel">
                        <div className="broker-data">
                            <div className="broker-data-io details-section">
                                {individualAccessRequestsData?.map((data: any) => {
                                    return (
                                        <>
                                        <div className="row details-row">
                                            <div className="col-md-3">
                                                <div className="header">Vessel</div>
                                                <div className="value">{data.vesselName}</div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header">Name</div>
                                                <div className="value">{data.vesselName}</div>
                                            </div>
                                            <div className="col-md-4 input-space">
                                                <div className="header">E-mail</div>
                                                <div className="value">{data.createdBy}</div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="header">Access type</div>
                                                <div className="value">
                                                    {data.vesselIMO}
                                                </div>
                                            </div>
                                        </div>
                                        <div  className="row details-row">
                                            <div className="col-md-3">
                                                <div className="header">Open Port</div>
                                                <div className="value">
                                                    {data.openPort}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="header">Open Range</div>
                                                <div className="value">
                                                    {data.openRange}
                                                </div>
                                            </div>
                                        
                                        </div>
                                        </>
                                    )

                                })}
                            </div>
                        </div>
                    </div>
                </McTabBar>
            </div>
            <McButton
                slot="secondaryAction"
                variant="primary"
                dialogAction="cancel"
                onClick={onCloseFunc}
                role="cancel-cp"
                fit="small"
            >
                Close
            </McButton>
        </McModal>
    )
}

export default WriteAccessNewModal