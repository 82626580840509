import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./IndicationInvite.scss";
import MultipleFileUpload from "../MultipleFileUpload/MultipleFileUpload";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
//MDS
import {
  McButton,
  McInputDate,
  McIcon,
  McSelect,
} from "@maersk-global/mds-react-wrapper";
import {
  getAPI,
  postAPI,
  fileApiAxios,
  deleteAPI,
  downloadReportDocuments,
} from "../../../services/CepApiServices";
import ConfirmationModal from "./ConfirmationModal";
import RejectModal from "./RejectModal";
import { AppString } from "../../../utils/AppString";
import DocumentUpload from "./DocumentUpload";
import { IconPdfDL } from "../../../components/Icons/pdfIcon";

const IndicationInvite = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const [fileType, setFileType] = useState();
  const [dataValue, setCMResponse] = useState({
    deliveryPort: "",
    id: null,
    indicationRequestId: null,
    userTypeId: null,
    userType: "",
    laycanFrom: null,
    laycanTo: null,
    period: null,
    timeCharterOptions: null,
    hire: null,
    redeliveryRange: "",
    addressCommission: null,
    additionalBrokerCommission: null,
    charterParty: "",
    charterBodApproval: null,
    ownersBodApproval: null,
    createdby: "",
    indicationRequestDocuments: null,
  });
  const [isConfrmModal, setIsConfrmModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState(false);
  const [deleteFileData, setDeleteUploadedFileData] = useState<any>({});
  const [reqObj, setPostAPIReqObj] = useState();
  const [uploadmodal, setUploadModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState<File>();
  const [allUploadedFiles, setAllUploadedFiles] = useState<any>([]);

  const [indicationReqDocs, setIndicationReqDocs] = useState<any>([]);
  const [cmDocumentDetails, setCmDocumentDetails] = useState<any>([]);
  const [remDocument, setRemDocument] = useState<any>([]);
  const [uploadedDoc, setuploadedDoc] = useState<any>([]);
  const [voDocUploadDetails, setVoDocUploadDetails] = useState<any>([]);

  const [documentsData, setDocumentsData] = useState(false);
  const [laycanFrom, setLaycanFromDate] = useState<any>();
  const [laycanTo, setLaycanToDate] = useState<any>();
  const [disableBtnVesselOwner, setDisableBtnVesselOwner] = useState(false);
  const [userType, setUserType] = useState();
  const [submittedUserName, setSubmittedUserName] = useState();
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [rejectComment, setRejectComment] = useState("");
  const [statusId, setStatusId] = useState();
  const [laycan, setLaycan] = useState<any>({ laycan: "" });
  const [isLaycanFrom, setIsLaycanFrom] = useState(true);
  const [isLaycanTo, setIsLaycanTo] = useState(true);

  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    getCharterManagerResponse("CharteringManager");
  }, []);

  const getCharterManagerResponse = async (userType: any) => {
    setIsTableLoader(true);
    const indicationRequestId = id;
    await getAPI(
      "IndicationRequest/GetIndicationRequestDataAsync/" + indicationRequestId
    ).then((result) => {
      setCMResponse(result);
      setIndicationReqDocs(result?.indicationRequestDocuments);
      const docDetails = (result?.indicationRequestDocuments || []).map(
        (doc) => ({
          id: doc.id,
          documentId: doc.documentId,
          documentName: doc.documentName,
          status: "available",
        })
      );
      setuploadedDoc(result?.indicationRequestDocuments.filter(
        (doc) => doc.uploadedFileName !== null
      ));
      setCmDocumentDetails(docDetails);
      setRemDocument(docDetails);

      if (indicationReqDocs?.length == 0) {
        setDocumentsData(true);
      }
      if (result?.laycanFrom)
        setLaycanFromDate(moment(result?.laycanFrom).format("DD/MM/YYYY"));
      if (result?.laycanTo)
        setLaycanToDate(moment(result?.laycanTo).format("DD/MM/YYYY"));
      if ((result?.userType !== "CharteringManager" && result?.userType !== "charteringManagerRepresentative") || result?.statusId == 4)
        setDisableBtnVesselOwner(true);
      if (result?.userType !== null) {
        setUserType(result?.userType);
        setSubmittedUserName(result?.createdby);
      }
      if (result?.statusId) {
        setStatusId(result?.statusId);
      }
    });
    setIsTableLoader(false);
  };

  const onSubmit = (data) => {
    if (data.laycanFrom === "" && dataValue.laycanFrom === null)
      return setIsLaycanFrom(false);
    else if (data.laycanFrom === null) return setIsLaycanFrom(false);
    if (data.laycanTo === "" && dataValue.laycanTo === null)
      return setIsLaycanTo(false);
    else if (data.laycanTo === null) return setIsLaycanTo(false);
    if (isLaycanTo === false) return;
    setPostAPIReqObj(data);
    setIsConfrmModal(true);
  };
  const deleteHandler = (id) => {
    setIsDeleteModal(true);
    setDeleteFileId(id);
  };
  const onDeleteVOUploaded = (response) => {
    if (response === "Yes") {
      const updatedVoDocUploadDetails = voDocUploadDetails.filter(
        (doc) => doc.id !== deleteFileId
      );
      setVoDocUploadDetails(updatedVoDocUploadDetails);

      const updatedCmDocumentDetails = cmDocumentDetails.map((doc) => {
        if (doc.id === deleteFileId) {
          return {
            ...doc,
            status: "available",
          };
        }
        return doc;
      });
      setCmDocumentDetails(updatedCmDocumentDetails);
      setIsDeleteModal(false);
      notify("File Deleted Successfully", true);
    } else {
      setIsDeleteModal(false);
    }
  };
  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };
  const saveVesselOwnerResp = async (data) => {
    const indicationRequestDocObj: any = [];
    for (let i = 0; i < voDocUploadDetails?.length; i++) {
      indicationRequestDocObj.push({
        id: voDocUploadDetails[i].id,
        documentId: voDocUploadDetails[i]?.documentId,
        formFile: voDocUploadDetails[i]?.formFile,
        indicationRequestId: id,
        documentName: voDocUploadDetails[i]?.documentName,
        createdBy: "",
        modifiedBy: "",
      });
    }
    const userTypeValue = "Vessel Owner";
    const userTypeId = 53;
    const bodyData = {
      id: 0,
      indicationRequestId: id,
      userTypeId: userTypeId,
      deliveryPort: data?.deliveryPort
        ? data.deliveryPort
        : dataValue?.deliveryPort
        ? dataValue?.deliveryPort
        : null,
      laycanFrom: data?.laycanFrom
        ? moment(data.laycanFrom, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss")
        : dataValue?.laycanFrom
        ? dataValue?.laycanFrom
        : null,
      laycanTo: data?.laycanTo
        ? moment(data.laycanTo, "DD/MM/YYYY").format("YYYY-MM-DD[T]HH:mm:ss")
        : dataValue?.laycanTo
        ? dataValue?.laycanTo
        : null,
      period: data?.period
        ? data.period
        : dataValue?.period
        ? dataValue?.period
        : null,
      timeCharterOptions: data?.timeCharterOptions
        ? data.timeCharterOptions
        : dataValue?.timeCharterOptions
        ? dataValue?.timeCharterOptions
        : null,
      hire: data?.hire ? data.hire : dataValue?.hire ? dataValue?.hire : null,
      redeliveryRange: data?.redeliveryRange
        ? data.redeliveryRange
        : dataValue?.redeliveryRange
        ? dataValue?.redeliveryRange
        : null,
      addressCommission: data?.addressCommission
        ? data.addressCommission
        : dataValue?.addressCommission
        ? dataValue?.addressCommission
        : null,
      additionalBrokerCommission: data?.additionalBrokerCommission
        ? data.additionalBrokerCommission
        : dataValue?.additionalBrokerCommission
        ? dataValue?.additionalBrokerCommission
        : null,
      charterParty: data?.charterParty
        ? data.charterParty
        : dataValue?.charterParty
        ? dataValue?.charterParty
        : null,
      charterBodApproval: data?.charterBodApproval
        ? data.charterBodApproval
        : dataValue?.charterBodApproval
        ? dataValue?.charterBodApproval
        : null,
      ownersBodApproval: data?.ownersBodApproval
        ? data.ownersBodApproval
        : dataValue?.ownersBodApproval
        ? dataValue?.ownersBodApproval
        : null,
    };
    let formData: any = new FormData();
    for (const key of Object.keys(bodyData)) {
      formData.append(key, bodyData[key]);
    }
    if (fileUploaded) {
      indicationRequestDocObj.forEach((val, ind) => {
        formData.append(
          `indicationRequestDocuments[${ind}].formFile`,
          val?.formFile
        );
        formData.append(`indicationRequestDocuments[${ind}].id`, val?.id);
        formData.append(
          `indicationRequestDocuments[${ind}].documentId`,
          val?.documentId
        );
        formData.append(
          `indicationRequestDocuments[${ind}].indicationRequestId`,
          val?.indicationRequestId
        );
        formData.append(
          `indicationRequestDocuments[${ind}].documentName`,
          val?.documentName
        );
        formData.append(
          `indicationRequestDocuments[${ind}].documentTypeId`,
          val?.documentTypeId
        );
        formData.append(
          `indicationRequestDocuments[${ind}].createdBy`,
          val?.createdBy
        );
        formData.append(
          `indicationRequestDocuments[${ind}].modifiedBy`,
          val?.modifiedBy
        );
      });
    }
    await fileApiAxios(
      "IndicationRequest/AddVesselOwnerResponse",
      formData
    ).then((result) => {
      if (result.data == true) {
        getCharterManagerResponse("Vessel Owner");
      }
      else{
        toast.error("Submission Failed", {
          theme: "colored",
          icon: false,
          autoClose: 3000,
        });
      }
    });
  };
  const onError = (errors, e) => console.log(errors, e);

  const saveOrCancelVesselOwnRespFunc = (response: string) => {
    if (response == "Yes") {
      saveVesselOwnerResp(reqObj);
      setIsConfrmModal(false);
    } else {
      setIsConfrmModal(false);
    }
  };

  const openUploadModal = () => {
    setUploadModal(true);
  };

  const closingUpload = () => {
    setUploadModal(false);
  };

  const receiveUploadedFile = (file: File, type: any) => {
    setFileUploaded(file);
    const newFileArr = [fileUploaded ? fileUploaded : file];
    const fileAllArr = newFileArr;
    fileAllArr.push(file);
    setAllUploadedFiles(fileAllArr);
    displayUploadedFile(file, type);
    const updatedDocumentDetails = cmDocumentDetails.map((doc) => {
      if (doc.documentId === type?.documentId) {
        return {
          ...doc,
          status: "uploaded",
        };
      }
      return doc;
    });
    setCmDocumentDetails(updatedDocumentDetails);
  };

  const displayUploadedFile = (file: File, type: any) => {
    if (file) {
      const obj = {
        id: type?.id,
        formFile: file,
        documentId: type?.documentId,
        documentName: file?.name,
        indicationRequestId: id,
        createdBy: null,
        modifiedBy: null,
      };
      let fileArr = voDocUploadDetails ? voDocUploadDetails : [];
      fileArr.push(obj);
      setVoDocUploadDetails(fileArr);
    }
  };

  const handleDownload = async (fileName: string, categoryName: any) => {
    await downloadReportDocuments(
      `DocumentLibrary/DownlaodDocument/${fileName}/${categoryName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("data is not coming");
      });
  };

  const DownloadUploadedDocument = async (id: any, fileName: string) => {
    let filesName = id + "_" + fileName;
    await downloadReportDocuments(
      `IndicationRequest/DownlaodIndicationRequestDocument/${filesName}`
    )
      .then((data) => data!.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        let a = document.createElement("a");
        let name = fileName;
        document.body.appendChild(a);
        a.href = url;
        a.download = `${name}`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => {
        console.log("data is not coming");
      });
  };

  const onBtnClickOut = (id: any, fileName: any) => {
    return handleDownload(id, fileName);
  };

  const onRejectModalOpen = () => {
    setOpenRejectModal(true);
  };

  const closeRejectModalFunc = async (type: string, data: any) => {
    if (type == "submit") {
      const rejectObj = {
        indicationId: Number(id),
        comment: data?.comments,
      };
      await postAPI(
        `IndicationRequest/IndicationRejectByVesselOwner`,
        rejectObj
      ).then((resp) => {
        setOpenRejectModal(false);
        if (resp === true) {
          getCharterManagerResponse("Vessel Owner");
        } else {
          toast.error("Reject Failed", {
            theme: "colored",
            icon: false,
          });
        }
      });
    } else {
      setOpenRejectModal(false);
    }
  };

  useEffect(() => {
    if (uploadmodal === false) {
      const remainingDocumentDetails = cmDocumentDetails.filter(
        (doc) => doc.status === "available"
      );
      setRemDocument(remainingDocumentDetails);
      if (remainingDocumentDetails.length === 0) {
        setDocumentsData(false);
      }
    }
  }, [cmDocumentDetails, uploadmodal]);

  const minDateChangeHandler = (e) => {
    const parsedDate = moment(e.target.value, "DD/MM/yyyy");
    const newDate: any = parsedDate.format("YYYY-MM-DD");
    setLaycan({ laycan: newDate });
    setValue("laycan", "");
  };

  const handleInputChange = (event) => {
    let inputValue = event.target.value;
    inputValue = parseInt(inputValue, 10);
    if(!isNaN(inputValue)){
      event.target.value = Math.abs(inputValue);
    }
  }

  return (
    <div className="indicationInvite-Main">
      {isTableLoader && (
        <div className="loader-div-sec">
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="container-fluid">
        <div className="header-invite-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Indication Request</h5>
            </div>
          </div>
        </div>

        {(userType !== "CharteringManager" && userType !== "charteringManagerRepresentative") && !isTableLoader && (
          <div className="notification-msg">
            <span>Response is submitted by {submittedUserName}</span>
          </div>
        )}

        {!isTableLoader && statusId == 4 && (
          <div className="notification-msg">
            <span>Indication is rejected by VO</span>
          </div>
        )}

        <div className="table-section-invite-page">
          <div className="table-div-invite-page bg-white">
            <form
              onSubmit={handleSubmit(onSubmit, onError)}
              data-testid="form-submit"
            >
              <div className="headingDiv-invite">
                <div className="indication-invite-button-group">
                  {!disableBtnVesselOwner && (
                    <McButton
                      className="invite-button-submit"
                      slot="primaryAction"
                      variant="primary"
                      type="submit"
                      data-testid="save-button"
                    >
                      Submit
                    </McButton>
                  )}
                </div>
                <div className="indication-invite-reject-button-group">
                  {!disableBtnVesselOwner && (
                    <McButton
                      className="invite-button-submit"
                      slot="secondaryAction"
                      variant="secondary"
                      click={() => onRejectModalOpen()}
                      data-testid="reject-button"
                    >
                      Reject
                    </McButton>
                  )}
                </div>
                <div className="invite-headingIcon">
                  <McIcon
                    icon={"file"}
                    color={"black"}
                    style={{ verticalAlign: "center" }}
                  ></McIcon>
                </div>
                <span className="textOfHeaderDiv">
                  {"Indication Invite Form"}
                </span>
              </div>
              <div className="row invite-user-details">
                <div className="col-sm-12 col-md-8">
                  <div className="row row-contents">
                    <div className="col-4">
                      <span>Account</span>
                      <span className="invite-inputText">Maersk A/S</span>
                    </div>

                    <div className="errorMargin col-4">
                      <span>Delivery Port</span>
                      <span className="asterisk">*</span>
                      <input
                        className="invite-inputText"
                        {...register("deliveryPort")}
                        required={true}
                        placeholder="Enter"
                        maxLength={200}
                        name="deliveryPort"
                        defaultValue={
                          dataValue?.deliveryPort !== null
                            ? dataValue?.deliveryPort
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="col-4">
                      <span>Laycan</span>
                      <span className="asterisk">*</span>
                      <div className="row">
                        <div className="col-5">
                          <McInputDate
                            label=""
                            {...register("laycanFrom")}
                            required
                            ariaPlaceholder="From date"
                            format="DD/MM/YYYY"
                            icon="calendar"
                            value={laycanFrom ? laycanFrom : null}
                            input={(e: any) => {
                              setLaycanFromDate(e.target.value);
                              setLaycanToDate("");
                              minDateChangeHandler(e);
                              setIsLaycanFrom(true);
                              setIsLaycanTo(false);
                            }}
                            disabled={disableBtnVesselOwner}
                            keypress={(e)=> e.preventDefault()}
                          ></McInputDate>
                          {isLaycanFrom ? (
                            <span></span>
                          ) : (
                            <span className="asterisk">
                              Please fill out this field
                            </span>
                          )}
                        </div>
                        <div className="col-5">
                          <McInputDate
                            label=""
                            {...register("laycanTo")}
                            required
                            ariaPlaceholder="To date"
                            format="DD/MM/YYYY"
                            icon="calendar"
                            value={laycanTo ? laycanTo : null}
                            min={laycan.laycan}
                            input={(e: any) => {
                              setLaycanToDate(e.target.value);
                              setIsLaycanTo(true);
                            }}
                            disabled={disableBtnVesselOwner}
                            keypress={(e)=> e.preventDefault()}
                          ></McInputDate>
                          {isLaycanTo ? (
                            <span></span>
                          ) : (
                            <span className="asterisk">
                              Please fill out this field
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row row-contents">
                    <div className="col-4">
                      <span>Period</span>
                      <span className="asterisk">*</span>
                      <input
                        className="invite-inputText"
                        {...register("period")}
                        required={true}
                        maxLength={200}
                        placeholder="Enter"
                        name="period"
                        defaultValue={
                          dataValue?.period !== null ? dataValue?.period : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="errorMargin col-4">
                      <span>Time Charter Options</span>
                      <input
                        className="invite-inputText"
                        {...register("timeCharterOptions")}
                        placeholder="Enter"
                        name="timeCharterOptions"
                        maxLength={200}
                        defaultValue={
                          dataValue?.timeCharterOptions !== null
                            ? dataValue?.timeCharterOptions
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="errorMargin col-4">
                      <span>Hire</span>
                      <span className="asterisk">*</span>
                      <div className="hire-currency">
                        <div className="col-3">
                          <McSelect
                            {...register("currency")}
                            name="currency"
                            label=""
                            disabled={true}
                            ariaPlaceholder="USD"
                          ></McSelect>
                        </div>
                        <div className="col-4">
                          <input
                            className="hire-inputText"
                            {...register("hire")}
                            onChange={handleInputChange}
                            required={true}
                            placeholder="Enter"
                            name="hire"
                            type="number"
                            defaultValue={
                              dataValue?.hire !== null ? dataValue?.hire : ""
                            }
                            disabled={disableBtnVesselOwner}
                            min="0"
                          ></input>
                        </div>
                        <span className="currency-text">/pdpr inclot</span>
                      </div>
                    </div>
                  </div>

                  <div className="row row-contents">
                    <div className="col-4">
                      <span>Redelivery Range</span>
                      <span className="asterisk">*</span>
                      <input
                        className="invite-inputText"
                        {...register("redeliveryRange")}
                        required={true}
                        maxLength={200}
                        name="redeliveryRange"
                        defaultValue={
                          dataValue?.redeliveryRange !== null
                            ? dataValue?.redeliveryRange
                            : ""
                        }
                        placeholder="Enter"
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="errorMargin col-4">
                      <span>Address Commission</span>
                      <input
                        className="invite-inputText"
                        {...register("addressCommission")}
                        placeholder="Enter"
                        maxLength={200}
                        name="addressCommission"
                        defaultValue={
                          dataValue?.addressCommission !== null
                            ? dataValue?.addressCommission
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="col-4">
                      <span>Additional Broker's Commission</span>

                      <input
                        className="invite-inputText"
                        {...register("additionalBrokerCommission")}
                        placeholder="Enter"
                        name="additionalBrokerCommission"
                        maxLength={200}
                        defaultValue={
                          dataValue?.additionalBrokerCommission !== null
                            ? dataValue?.additionalBrokerCommission
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>
                  </div>

                  <div className="row row-contents">
                    <div className="col-4">
                      <span>Charter Party</span>
                      <span className="asterisk">*</span>

                      <input
                        className="invite-inputText"
                        {...register("charterParty")}
                        required={true}
                        maxLength={200}
                        placeholder="Enter"
                        name="charterParty"
                        defaultValue={
                          dataValue?.charterParty !== null
                            ? dataValue?.charterParty
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="errorMargin col-4">
                      <span>Owner's BOD Approval</span>
                      <span className="asterisk">*</span>

                      <input
                        className="invite-inputText"
                        {...register("ownersBodApproval")}
                        required={true}
                        maxLength={200}
                        placeholder="Enter"
                        name="ownersBodApproval"
                        defaultValue={
                          dataValue?.ownersBodApproval !== null
                            ? dataValue?.ownersBodApproval
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>

                    <div className="col-4">
                      <span>Charterer's BOD Approval</span>
                      <span className="asterisk">*</span>
                      <input
                        className="invite-inputText"
                        {...register("charterBodApproval")}
                        required={true}
                        maxLength={200}
                        placeholder="Enter"
                        name="charterBodApproval"
                        defaultValue={
                          dataValue?.charterBodApproval !== null
                            ? dataValue?.charterBodApproval
                            : ""
                        }
                        disabled={disableBtnVesselOwner}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="upload-section-invite">
              <div className="headingDiv-invite-cm">
                <span className="textOfHeaderDiv">
                  {"Document Requested By Chartering Manager"}
                </span>
                <span className="information-text">
                  <McIcon
                    icon="info-circle"
                    className="information-icon"
                    color="black"
                  ></McIcon>
                  {"Download to fill and upload the Document"}
                </span>
              </div>
              {indicationReqDocs?.length == 0 && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="NoFilesSectionInvite">
                      <span>No Files Found</span>
                    </div>
                  </div>
                </div>
              )}
              {indicationReqDocs?.length > 0 && (
                <div className="bodySectionInvite">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        {indicationReqDocs?.map((data: any) => (
                          <div className="col-md-3">
                            <div className="document-div">
                              <div className="download-icon">
                                <McIcon
                                  data-testid="download-doc"
                                  icon="tray-arrow-down"
                                  onClick={() => {
                                    onBtnClickOut(data?.documentName, "Other Document");
                                  }}
                                ></McIcon>
                              </div>
                              <p className="file-name" data-testid="file-name">
                                <IconPdfDL
                                  nameOfDoc={
                                    data?.documentName?.split(".").slice(-1)[0]
                                  }
                                ></IconPdfDL>
                                {"  "}
                                {data?.documentName
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="upload-section-invite">
              <div className="headingDiv-invite uploadHeadingInvite">
                <div className="uploadButtonInvite">
                  {!disableBtnVesselOwner && (
                    <McButton
                      data-testid="upload-file"
                      icon="tray-arrow-up"
                      onClick={openUploadModal}
                      disabled={remDocument.length === 0}
                    >
                      Upload Document
                    </McButton>
                  )}
                </div>
                <span className="textOfHeaderDiv">{"Uploaded Documents"}</span>
              </div>
              {(voDocUploadDetails?.length == 0 && uploadedDoc?.length == 0) && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="NoFilesSectionInvite">
                      <span>No Files Found</span>
                    </div>
                  </div>
                </div>
              )}
              {!disableBtnVesselOwner ? 
              <>{voDocUploadDetails?.length > 0 && (
                <div className="bodySectionInvite">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        {voDocUploadDetails?.map((data: any) => (
                          <div className="col-md-3">
                            <div className="attach-div-invite">
                              <div
                                  className="download-icon"
                                  onClick={() => {
                                    deleteHandler(data?.id);
                                  }}
                                >
                                  <McIcon icon="trash" color="#c00"></McIcon>
                              </div>
                              <p className="file-name" data-testid="file-name">
                                <IconPdfDL
                                  nameOfDoc={
                                    data?.documentName.split(".").slice(-1)[0]
                                  }
                                ></IconPdfDL>
                                {"  "}
                                {data?.documentName
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )} </> : <>
              {uploadedDoc?.length > 0 && (
                <div className="bodySectionInvite">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        {uploadedDoc?.map((data: any) => (
                          <div className="col-md-3">
                            <div className="attach-div-invite">
                              <div className="download-icon">
                                <McIcon
                                  data-testid="download-doc"
                                  icon="tray-arrow-down"
                                  onClick={() => {
                                    DownloadUploadedDocument(data?.id, data?.uploadedFileName);
                                  }}
                                ></McIcon>
                              </div>
                              <p className="file-name" data-testid="file-name">
                                <IconPdfDL
                                  nameOfDoc={
                                    data?.documentName.split(".").slice(-1)[0]
                                  }
                                ></IconPdfDL>
                                {"  "}
                                {data?.documentName
                                  .split(".")
                                  .slice(0, -1)
                                  .join(".")}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )} </>  }  
            </div>
          </div>
        </div>

        <DocumentUpload
          onOpen={uploadmodal}
          closeUpload={closingUpload}
          fileUpload={receiveUploadedFile}
          availableDocumentList={remDocument}
        />

        <ConfirmationModal
          onModalOpen={isConfrmModal}
          onModalClose={saveOrCancelVesselOwnRespFunc}
          modalTxt={AppString.saveModalIndicationRequestTxt}
        />

        <ConfirmationModal
          onModalOpen={isDeleteModal}
          onModalClose={onDeleteVOUploaded}
          modalTxt={AppString.deleteModalTxt}
        />

        <RejectModal
          onModalOpen={openRejectModal}
          onModalClose={closeRejectModalFunc}
        />
      </div>
    </div>
  );
};

export default IndicationInvite;
