import React, { useState, useEffect } from "react";
import { TableColumn } from "react-data-table-component";
import { McIcon } from "@maersk-global/mds-react-wrapper";
import moment from "moment";
import { StatusList } from "../../../utils/StatusList";
import VesselInfoModal from "./VesselInfoModal";
import ToolTipFormatter from "./ToolTipFormatter";
// import AssignRepresentativeModal from "../AssignRepresentativeModal/AssignRepresentativeModal";
import BrokerDetailsModal from "./BrokerDetailsModal";
//CSS
import DataTable from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import { getAPI } from "../../../services/CepApiServices";
// Styles
import "./VesselsList.scss";

interface IPROPS {
  loadData?: any;
  crId?: any;
  compareImoFunc?: any;
  clearChecked?: any;
  isDirectNegotiation?: any;
  filterApplied?: any;
  resetCheckBox?: any;
  searchVesselNameText?: any;
  handleUpdateStatus?: any;
  setAvailableVsl?: any;
}
type DataRow = {
  assignedTo: string;
  vesselName: string;
  isEstRedlBasedAIS: any;
  teu: string;
  design: string;
  managingOwner: string;
  currentOperator: string;
  openFrom: string;
  openRange: string;
  openPort: string;
  orderBook: string;
  missingEstRedelivery: boolean;
  multipleServiceDescription: any;
  workflowStageId: number;
  workflowStageName: string;
};
const VesselsList = ({
  loadData,
  crId,
  compareImoFunc,
  clearChecked,
  isDirectNegotiation,
  filterApplied,
  resetCheckBox,
  searchVesselNameText,
  handleUpdateStatus,
  setAvailableVsl
}: IPROPS) => {
  const [vesselsList, setVesselsList] = useState(false);
  const [vesselInfoModal, setVesselInfoModal] = useState(false);
  const [brokerDetailsModal, setBrokerDetailsModal] = useState(false);
  const [simulationdata, setSimulationdata] = useState<any>({});
  const [brokerDetailsData, setBrokerDetailsData] = useState<any>({});
  const [tableData, setTableData] = useState([]);
  const [mainResponse, setMainResponse] = useState([]);
  const [mainStatus, setMainStatus] = useState("");
  const [isTableLoader, setIsTableLoader] = useState(false);
  const checkDirectNegotiation = (vessels: any) => {
    // 6,8,9,10,11,12,13,14,15
    const isTrue: any = vessels?.some(
      (item: any) =>
        item?.workflowStageId === 6 ||
        item?.workflowStageId === 8 ||
        item?.workflowStageId === 9 ||
        item?.workflowStageId === 10 ||
        item?.workflowStageId === 11 ||
        item?.workflowStageId === 12 ||
        item?.workflowStageId === 13 ||
        item?.workflowStageId === 14 ||
        item?.workflowStageId === 15
    );
    if (isDirectNegotiation) {
      isDirectNegotiation(isTrue);
    }
  };
  const fetchVesselListCRDetails = async (crId) => {
    setIsTableLoader(true);
    const vesselsResponse = await getAPI(
      `CharterRequest/GetAvailableVessels/` + crId
    );
    setMainResponse(vesselsResponse?.vessels);
    setAvailableVsl(vesselsResponse?.vessels);
    setTableData(vesselsResponse?.vessels);
    checkDirectNegotiation(vesselsResponse?.vessels);
    setMainStatus(vesselsResponse?.charterStatus);
    handleUpdateStatus(vesselsResponse?.charterStatus);
    if (filterApplied) {
      filterApplied(vesselsResponse?.isFilterApplied);
    }
    setIsTableLoader(false);
    resetCheckBox();
  };
  const fetchSearchedVslData = (text) => {
    if (!text) {
      setTableData(mainResponse);
    } else {
      const filteredRows = mainResponse?.filter((row: any) => {
        return row?.vesselName
          ?.toLowerCase()
          .includes(searchVesselNameText?.toLowerCase());
      });
      setTableData(filteredRows);
    }
  };
  useEffect(() => {
    fetchSearchedVslData(searchVesselNameText);
  }, [searchVesselNameText]);

  useEffect(() => {
    fetchVesselListCRDetails(crId);
    console.log("loadData", JSON.stringify(loadData));
  }, [loadData]);

  const handleChange = ({ selectedRows }) => {
    setVesselsList(false);
    compareImoFunc(selectedRows);
  };
  const onModalFunc = (row: any) => {
    if (row.isEstRedlBasedAIS) {
      setVesselInfoModal(true);
      setSimulationdata(row?.vesselsBasedOnAisSimulation);
    }
  };
  const fetchBrokerDetails = async (data) => {
    let response: any;
    try {
      response = await getAPI(
        `IndicationRequest/GetCMRAssignment?CharterRequestId=` +
          crId +
          `&VesselIMO=` +
          data.imo
      );
      setBrokerDetailsData(response);
      setTimeout(() => {
        setBrokerDetailsModal(true);
      }, 1200);
    } catch (error) {
      console.log("error", error);
    }
  };
  const onOpenBrokerDetails = (row:any) => {
    fetchBrokerDetails(row);
  }
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const columns: TableColumn<DataRow>[] = [
    {
      name: "Status",
      selector: (row) => (row.workflowStageName ? row.workflowStageName : ""),
      cell: (row) => StatusList(row.workflowStageId, row.workflowStageName, ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "Vessel Name",
      selector: (row) => (row.vesselName ? row.vesselName.toLowerCase() : ""),
      cell: (row) => (row.vesselName ? row.vesselName : ""),
      sortable: true,
      width: "13%",
    },
    {
      name: "Order Book",
      selector: (row) => (row.orderBook ? row.orderBook : ""),
      sortable: true,
      width: "7%",
    },
    {
      name: "TEU",
      selector: (row) => (row.teu ? row.teu : ""),
      sortable: true,
      width: "5%",
    },
    {
      name: "Design",
      selector: (row) => (row.design ? row.design.toLowerCase() : ""),
      cell: (row) => (row.design ? row.design : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "Managing Owner",
      selector: (row) =>
        row.managingOwner ? row.managingOwner.toLowerCase() : "",
      cell: (row) => (row.managingOwner ? row.managingOwner : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "Operator",
      selector: (row) =>
        row.currentOperator ? row.currentOperator.toLowerCase() : "",
      cell: (row) => (row.currentOperator ? row.currentOperator : ""),
      sortable: true,
      width: "8%",
    },
    {
      name: "Assigned to",
      selector: (row) =>
        row.assignedTo ? row.assignedTo.toLowerCase() : "",
      cell: (row) => (row.assignedTo ? <span
        onClick={() => onModalFunc(row)}
      >
        {row.assignedTo}<span className="highlight-assignedTo" onClick={() => onOpenBrokerDetails(row)}>  See more</span>
        {/* {row.assignedTo}<span className="highlight-assignedTo">  See more</span> */}
      </span> : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "Open From",
      selector: (row) => (row.openFrom ? row.openFrom : ""),
      cell: (row) =>
        row.missingEstRedelivery ? (
          <div className="tool-tip">
            <span
              className="open-from-tooltip"
              // data-tooltip={row?.multipleServiceDescription}
            >
              <McIcon
                className="open-from-icon"
                icon="question-circle"
                color="#42b0d5"
              ></McIcon>
              <ToolTipFormatter toolTipList={row}/>
            </span>
          </div>
        ) : (
          <span
            className={row.isEstRedlBasedAIS && `heighligt highlight-underline`}
            onClick={() => onModalFunc(row)}
          >
            {moment(row.openFrom).format("DD-MMM-YYYY")}
          </span>
        ),
      sortable: true,
      width: "7%",
    },
    {
      name: "Open Range",
      selector: (row) => (row.openRange ? row.openRange : ""),
      cell: (row) => (
        <span className={row.isEstRedlBasedAIS && `heighligt`}>
          {row.openRange}
        </span>
      ),
      sortable: true,
      width: "7%",
    },
    {
      name: "Open Port",
      selector: (row) => (row.openPort ? row.openPort : ""),
      cell: (row) => (
        <span className={row.isEstRedlBasedAIS && `heighligt`}>
          {row.openPort}
        </span>
      ),
      sortable: true,
      width: "8%",
    },
  ];
  return (
    <div className="ir-table-main table-margin1 charterDetailsTable">
      <DataTable
        columns={columns}
        data={tableData}
        progressPending={isTableLoader}
        progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        selectableRows
        selectableRowsSingle
        selectableRowDisabled={(row) =>
          mainStatus === "Completed" ||
          (row.workflowStageId !== null && row.workflowStageName !== null)
        }
        clearSelectedRows={clearChecked}
        onSelectedRowsChange={handleChange}
      />
      <VesselInfoModal
        isModalOpen={vesselInfoModal}
        infoData={simulationdata}
        isCloseModal={() => setVesselInfoModal(false)}
      ></VesselInfoModal>
        {brokerDetailsModal && (<BrokerDetailsModal
        isModalOpen={brokerDetailsModal}
        infoData={brokerDetailsData}
        isCloseModal={() => setBrokerDetailsModal(false)}
      ></BrokerDetailsModal>
      )}
    </div>
  );
};

export default VesselsList;
