import { useEffect, useState } from "react";
import "./IndicationDocumentsDownload.scss";
import { DocsDownloadModel } from "./Model";
import DataTable from "react-data-table-component";
import { getAPI } from "../services/CepApiServices";
import { TableColumn } from "react-data-table-component";
import { SkeletonLoader } from "../components/skeletonLoader/SkeletonLoader";
import SelectDocumentModal from "./SelectDocumentModal";
import { McButton, McIcon } from "@maersk-global/mds-react-wrapper";
import { StatusList } from "../utils/StatusList";
import FilterComponent from "../components/FilterComponent/FilterComponent";
import { getUserRole } from "../utils/token";
const IndicationDocumentsDownload = () => {
  const [tableData, setTableData] = useState([]);
  const [mainData, setMainData] = useState<any>([]);
  const [indicationId, setIndicationId] = useState(0);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [isInitiateModal, setIsInitiateModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [documents, setDocuments] = useState<any>([]);
  const [isFilter, setIsFilter] = useState(false);
  const [dataObj, setDataObj] = useState<any>();
  const [userRole, setUserRole] = useState<any>("");

  const getInitiateOfferData = async () => {
    setIsTableLoader(true);
    await getAPI(`InitialOffer/GetAcceptedIndicationRequest`).then(
      (response) => {
        setTableData(response);
        setMainData(response);
      }
    );
    setIsTableLoader(false);
  };
  useEffect(() => {
    getInitiateOfferData();
  }, []);
  const onFilterClick = () => {
    setIsFilter(true);
  };
  const clearFilterFunc = () => {
    setDataObj({});
  };
  useEffect(() => {
    const getUserRoleAsync = async () => {
      const userRole = await getUserRole();
      setUserRole(userRole);
    };
    getUserRoleAsync();
  }, []);
  const filterDataFunc = (obj: any) => {
    const formData = {
      statusID: obj?.statusField ? obj?.statusField : null,
      requestItem: obj?.requestItems
        ? obj?.requestItems.map((item) => item.label)
        : null,
    };
    setDataObj(formData);
  };
  useEffect(() => {
    if (dataObj) {
      console.log(JSON.stringify(dataObj));
      if (Object.keys(dataObj)?.length > 0) {
        const newTableData = mainData?.filter(function (item: any) {
          if (dataObj?.statusID?.length > 0 && dataObj?.requestItem?.length > 0) {
            return (
              dataObj?.statusID?.indexOf(item?.statusId) !== -1 &&
              dataObj?.requestItem?.indexOf(item?.requestName) !== -1
            );
          } else if (dataObj?.statusID?.length > 0) {
            return dataObj?.statusID?.indexOf(item?.statusId) !== -1;
          } else if (dataObj?.requestItem?.length > 0) {
            return dataObj?.requestItem?.indexOf(item?.requestName) !== -1;
          } else {
            return item;
          }
        });
        setTableData(newTableData);
      } else {
        getInitiateOfferData();
      }
    }
  }, [dataObj]);

  const OfferStatus = (row: any) => {
    return (
      <McButton
        label="View"
        onclick={(e) => handleViewDocs(row)}
        fit="small"
        data-testid="View"
      ></McButton>
    );
  };

  const handleViewDocs = (row: any) => {
    setIndicationId(row.id);
    fetchAllDocs(row.id);
  };

  const fetchAllDocs = async (id: any) => {
    const response = await getAPI(
      `IndicationRequest/GetUploadedIndicationDocuments?indicationId=` + id
    );

    if (response.StatusCode) {
      console.log("error");
    } else {
      setDocuments(response);
      setIsInitiateModal(true);
    }
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const columns: TableColumn<DocsDownloadModel>[] = [
    {
      name: "Status",
      selector: (row) =>
        row.indicationStatus !== null ? row.indicationStatus : "",
      cell: (row) => StatusList(row.statusId, row.indicationStatus, ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width: "8%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row.requestName !== null ? row.requestName.toLowerCase() : "",
      cell: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row.vesselName !== null ? row.vesselName.toLowerCase() : "",
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width: "12%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row?.charteringManagerName !== null ? row?.charteringManagerName.toLowerCase() : "",
      cell: (row) => (row?.charteringManagerName !== null ? row?.charteringManagerName : ""),
      sortable: true,
    },
    {
      name: "VO/Owner Representative",
      selector: (row) =>
        row?.vesselOwnerName !== null
          ? row?.vesselOwnerName.toLowerCase()
          : "",
      cell: (row) =>
        row?.vesselOwnerName !== null ? row?.vesselOwnerName : "",
      sortable: true,
    },
    {
      name: "",
      cell: (row) => OfferStatus(row),
      width: "9%",
    },
  ];

  const cancelPopup = (e: any) => {
    setIsInitiateModal(false);
    setIndicationId(0);
  };

  return (
    <div className="negotiation-main-table">
      <div className="container-fluid">
        <div className="header-div">
          <div className="row">
            <div className="col-md-3">
              <h5>Indication Request Documents</h5>
            </div>
            {(userRole == "CharteringManager" || userRole == "charteringManagerRepresentative") && (
              <div className="col-md-2 filter-buttons">
                <button
                  className="clear-button"
                  onClick={clearFilterFunc}
                  data-testid="dwnd-clear-filter-button"
                >
                  Clear
                </button>
                <button
                  onClick={onFilterClick}
                  data-testid="apply-filter-button"
                  className="negotiation-filter-button"
                >
                  <span>
                    <McIcon icon="sliders-horizontal"></McIcon>
                  </span>
                  <span>Filter</span>
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="table-section">
          <div className="documentsDownloadPage">
            <SelectDocumentModal
              indicationId={indicationId}
              documents={documents}
              isModalOpen={isInitiateModal}
              onLoadData={modalData}
              onCanceled={cancelPopup}
            />

            <div className="ir-table-main table-margin2">
              <DataTable
                columns={columns}
                data={tableData}
                progressPending={isTableLoader}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                progressComponent={
                  <SkeletonLoader isLoader={true}></SkeletonLoader>
                }
              />
            </div>
          </div>
        </div>
        <div className="negotiation-filter-section">
          {isFilter && (
            <FilterComponent
              filterFor={"IndicationRequestDocuments"}
              onDataFunc={filterDataFunc}
              closeFilterFunc={() => setIsFilter(false)}
              clearFilter={clearFilterFunc}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IndicationDocumentsDownload;
