import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./InitiateOfferTable.scss";
import { InitiateOfferModel } from "./Model";
import DataTable from "react-data-table-component";
import { getAPI,postAPI } from "../../../services/CepApiServices";
import { TableColumn } from "react-data-table-component";
import { SkeletonLoader } from "../../../components/skeletonLoader/SkeletonLoader";
import InitiateOfferModal from "./InitiateOfferModal";
import ExtendContractModal from "./ExtendContractModal";
import { McButton, McIcon, McNotification } from "@maersk-global/mds-react-wrapper";
import { StatusList } from "../../../utils/StatusList";
import { getUserRole } from "../../../utils/token";
import WriteAccessModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessModal";
import DirectIndication from "../../../CharterRequest/RequestPages/SendIndicationModal/SendDirectIndication";
import moment from "moment";
import { toast } from "react-toastify";
import ManageWriteAccessRequestsModal from "../../../CharterRequest/RequestPages/ManageWriteAccessRequestsModal/ManageWriteAccessRequestsModal";
import WriteAccessNewModal from "../../../IndicationRequest/IndicationRequestPages/IndicationRequestTable/WriteAccessNewModal";
import ManageAccessModal from "../../../CharterRequest/RequestPages/ManageAccessModal/ManageAccessModal";
import RequestWriteAccessModal from "../../../CharterRequest/RequestPages/RequestWriteAccessModal/RequestWriteAccessModal";

interface IPROPS {
  filterData?: any;
}
const InitiateOfferTable = ({ filterData }: IPROPS) => {
  const [tableData, setTableData] = useState([]);
  const [mainData, setMainData] = useState<any>([]);
  const [isTableLoader, setIsTableLoader] = useState(false);
  const [isInitiateModal, setIsInitiateModal] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [extendModalData, setExtendModalData] = useState<any>();
  const [isExtendModal, setIsExtendModal] = useState(false);
  const [directNegtnModal, setIsDirectNegtnModal] = useState(false);
  const [extendContractRowDetails, setExtendContractRowDetails] =
    useState<any>();
  const [showColumn, setShowColumn] = useState(true);
  const [isWriteAccess, SetIsWriteAccess] = useState(false);
  const [rowData, setRowData] = useState<any>();
  const [userRole, setUserRole] = useState<any>();
  const [manageWriteRequestModal,setManageWriteRequestModal] = useState(false);
  const [showNotification,setShowNotification] = useState(false);
  const [accessRequestsData, setAccessRequestsData] = useState<any>();
  const [individualAccessRequestsData, setIndividualAccessRequestsData] = useState<any>();
  const [brokerDetailsData, setBrokerDetailsData] = useState<any>({});
  const [charterRequestId, setCharterRequestId] = useState(0);
  const [imo, setIMO] = useState(0);
  const [isWriteAccessCMR,setIsWriteAcessCMR] = useState(false);
  const [isRequestWriteAccessModal, setIsRequestWriteAccessModal] = useState(false);
  const [assignmentId, setAssignmentId] = useState(0);
  const [disableRequestWriteAccessBtn, setDisableRequestWriteAccessBtn] = useState(false);

  const history = useNavigate();
  const getInitiateOfferData = async () => {
    setIsTableLoader(true);
    await getAPI(`InitialOffer/GetAcceptedIndicationRequest`).then(
      (response) => {
        setTableData(response);
        setMainData(response);
      }
    );
    setIsTableLoader(false);
  };
  const getNotificationForCMR = async () => {
    await getAPI(`IndicationRequest/GetWriteAccessRequests`).then(
      (response) => {
        if(response.length >= 1) {
        setShowNotification(true);
        setAccessRequestsData(response);
        }
        else {
          setShowNotification(false);
        }

      }
    );
  }
  const getUserRoleAsync = async () => {
    const userRole = await getUserRole();
    setUserRole(userRole);
    if (userRole === "Deploymentmanager") {
      setShowColumn(false);
    } else if (userRole === "CharteringManager") {
      setShowColumn(true);
    } else if (userRole === "charteringManagerRepresentative") {
      getNotificationForCMR();
    }
  };
  const fetchBrokerDetails = async (data) => {
    let response: any;
    try {
      response = await getAPI(
        `IndicationRequest/GetCMRAssignment?CharterRequestId=` +
        data?.charterRequestId +
          `&VesselIMO=` +
          data?.imo
      );
      setBrokerDetailsData(response);
      setAssignmentId(response?.assignmentId);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getUserRoleAsync();
    getInitiateOfferData();
  }, []);
  const pageReload = () => {
    getInitiateOfferData();
  };
  useEffect(() => {
    if (filterData) {
      if (Object.keys(filterData)?.length > 0) {
        const newTableData = mainData?.filter(function (item: any) {
          if (
            filterData?.statusID?.length > 0 &&
            filterData?.requestItem?.length > 0
          ) {
            return (
              filterData?.statusID?.indexOf(item?.statusId) !== -1 &&
              filterData?.requestItem?.indexOf(item?.requestName) !== -1
            );
          } else if (filterData?.statusID.length > 0) {
            return filterData?.statusID?.indexOf(item?.statusId) !== -1;
          } else if (filterData?.requestItem?.length > 0) {
            return filterData?.requestItem?.indexOf(item?.requestName) !== -1;
          } else {
            return item;
          }
        });
        setTableData(newTableData);
      } else {
        getInitiateOfferData();
      }
    }
  }, [filterData]);
  const OfferStatus = (row: any) => {
    if (row.accessType == "Write" && row.statusId == 6) {
      return (
        <McButton
          data-testid="initiate-button"
          label="Initiate"
          onclick={(e) => handleInitiateOffer(row)}
          fit="small"
        ></McButton>
      );
    } else if (row.accessType == "Read" && row.statusId == 6) {
      return <McButton label="Initiate" fit="small" disabled></McButton>;
    } else {
      return (
        <McButton
          data-testid="view-button"
          label="View"
          onclick={(e) => handleViewOffer(row)}
          fit="small"
        ></McButton>
      );
    }
  };
  const extendContractBtn = (row: any) => {
    if (row.statusId == 17) {
      return (
        <McButton
          data-testid="extend-button"
          label="Extend"
          onclick={(e) => handleExtendContract(row)}
          fit="small"
          disabled={row.isContractExtended == "Yes"}
        ></McButton>
      );
    }
  };
  const handleWriteAssigneeFun = (value: any) => {
    // SetIsWriteAccess(true);
    setRowData(value);
    setCharterRequestId(value.charterRequestId);
    setIMO(value?.imo);
    fetchBrokerDetails(value);
    getAccessRequestsData(value);
    setTimeout(() => {
      if(userRole == "CharteringManager") {
      SetIsWriteAccess(true);
      } else if(userRole == "charteringManagerRepresentative") {
        setIsWriteAcessCMR(true);
      }
      // SetIsWriteAccess(true);
    }, 1800);
  };

  const getRequestWriteAccess = async()=> {
    const response = await getAPI(
      `IndicationRequest/RequestCMRWriteAccess?CMRAssignmentId=` + assignmentId
    );
    if(response) {
      // SetIsWriteAccess(false);
      setIsRequestWriteAccessModal(false);
      setDisableRequestWriteAccessBtn(true);
    }
  }
  const handleAssignee = (row: any) => {
    if (
      // row?.statusId !== 1 &&
      // row?.statusId !== 3 &&
      // row?.statusId !== 7 &&
      row.accessType === "Read"
    ) {
      return (
        <span
          className="manage-access"
          data-testid="manage-access"
          onClick={() => handleWriteAssigneeFun(row)}
        >
          Manage Access
        </span>
      );
    } else {
      return "--";
    }
  };
  const closeFunc = () => {
    SetIsWriteAccess(false);
  };
  const handleViewOffer = (row: any) => {
    history(`/InitiateOfferDetails?id=${row.id}`, {
      state: {
        isExtended: false,
        negotiationID: row?.id,
        extendedContractId: row?.extendedContractId,
        indicationStatus: row?.indicationStatus,
      },
    });
  };
  const accessTypeSetter = (accessType: any) => {
    if (accessType == "Read") {
      return (
        <span className="read-access">
          <McIcon icon="eye" className="read-access-icon"></McIcon>
          {accessType}
        </span>
      );
    } else if (accessType == "Write") {
      return (
        <span className="write-access">
          <McIcon icon="pencil" className="write-access-icon"></McIcon>
          {accessType}
        </span>
      );
    }
  };
  const handleInitiateOffer = (row: any) => {
    setIsInitiateModal(true);
    setModalData(row);
    // history(`/InitiateOfferDetails?id=${row.id}&section=CM`)
  };
  const handleExtendContract = (row: any) => {
    setIsExtendModal(true);
    setIsDirectNegtnModal(false);
    setExtendModalData(row);
  };
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL",
  };
  const columns: TableColumn<InitiateOfferModel>[] = [
    {
      name: "Status",
      selector: (row) =>
        row.indicationStatus !== null ? row.indicationStatus : "",
      cell: (row) => StatusList(row.statusId, row.indicationStatus, ""),
      sortable: true,
      width: "9%",
    },
    {
      name: "Request Name",
      selector: (row) =>
        row.requestName !== null ? row.requestName.toLowerCase() : "",
      cell: (row) => (row.requestName !== null ? row.requestName : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "Vessel Name",
      selector: (row) =>
        row.vesselName !== null ? row.vesselName.toLowerCase() : "",
      cell: (row) => (row.vesselName !== null ? row.vesselName : ""),
      sortable: true,
      width: "10%",
    },
    {
      name: "IMO",
      selector: (row) => (row.imo !== null ? row.imo : ""),
      sortable: true,
      width: "5%",
    },
    {
      name: "Chartering Manager",
      selector: (row) =>
        row?.charteringManagerName !== null ? row?.charteringManagerName?.toLowerCase() : "",
      cell: (row) => (row?.charteringManagerName !== null ? row?.charteringManagerName : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "CM Representative",
      selector: (row) =>
        row?.charteringManagerRepresentativeName !== null ? row?.charteringManagerRepresentativeName?.toLowerCase() : "",
      cell: (row) => (row?.charteringManagerRepresentativeName !== null ? row?.charteringManagerRepresentativeName : ""),
      sortable: true,
      width: "11%",
    },
    {
      name: "Vessel Owner",
      selector: (row) =>
        row?.vesselOwnerName !== null
          ? row?.vesselOwnerName?.toLowerCase()
          : "",
      cell: (row) =>
        row?.vesselOwnerName !== null ? row?.vesselOwnerName : "",
      sortable: true,
      width: "9%",
    },
    {
      name: "VO Representative",
      selector: (row) =>
        row?.vesselOwnerRepresentativeName !== null
          ? row?.vesselOwnerRepresentativeName.toLowerCase()
          : "",
      cell: (row) =>
        row?.vesselOwnerRepresentativeName !== null ? row?.vesselOwnerRepresentativeName : "",
      sortable: true,
      width: "9%",
    },
    ...((showColumn
      ? [
        {
          name: "Created On",
          selector: (row) => (row.createdOn !== null ? row.createdOn : ""),
          cell: (row) => moment(row.createdOn).format("DD-MMM-YYYY"),
          sortable: true,
          width: "7%",
        },
        // {
        //   name: "Direct Negotiation",
        //   selector: (row) => row.isDirectNegotiation,
        //   sortable: true,
        //   width: "6%",
        // },
        // {
        //   name: "Access Type",
        //   selector: (row) => (row.accessType !== null ? row.accessType : ""),
        //   cell: (row) => accessTypeSetter(row.accessType),
        //   sortable: true,
        //   width: "6%",
        // },
        {
          name: "Access",
          cell: (row) => handleAssignee(row),
          width: "7%",
        },
        {
          name: "",
          cell: (row) => OfferStatus(row),
          width: "5%",
        },
        {
          name: "",
          cell: (row) => extendContractBtn(row),
          width: "6%",
        },
      ]
      : []) as any),
  ];

  const cancelPopup = (e: any) => {
    setIsInitiateModal(false);
  };

  const cancelExtendPopup = (type: any) => {
    setIsExtendModal(false);
    if (type == "Submit") {
      setIsDirectNegtnModal(true);
    } else {
      setIsDirectNegtnModal(false);
    }
  };

  const closeDirectIndicationModal = () => {
    setIsDirectNegtnModal(false);
  };

  const openWriteAccessRequests = () => {
    setManageWriteRequestModal(true);
  }

  const onWriteAccessRequestsModalClose = () => {
    setManageWriteRequestModal(false);
  }

  const notify = (msg: any, flag: any) => {
    if (flag) {
      toast.success(msg, {
        theme: "colored",
        icon: false,
      });
    } else {
      toast.error(msg, {
        theme: "colored",
        icon: false,
      });
    }
  };

  const setGiveAccessData = async (obj: any): Promise<void> => {
      const payload = obj;
      const status = await postAPI(`IndicationRequest/AssignWriteAccessToCM`, payload);
      if (status.StatusCode == 400 || status.message) {
        setTimeout(() => {
          notify("Failed to change access", false)
        }, 1000);
        setManageWriteRequestModal(false);
      }
      else {
        setTimeout(() => {
          notify("Access changed successfully.", true)
        }, 1000);
        setManageWriteRequestModal(false);
        getInitiateOfferData();
        getNotificationForCMR();
      }
    };

    const getAccessRequestsData = async (data) => {
      await getAPI(`IndicationRequest/GetWriteAccessRequests?charterRequestId=${data?.charterRequestId}&vesselImo=${data?.imo}`).then(
          (response) => {
              if (response) {
                  setIndividualAccessRequestsData(response);
              }
              else {
                  //   setShowNotification(false);
              }

          }
      );
  }
  const requestWriteAccess = ()=> {
    setIsRequestWriteAccessModal(true);
  }

  const closeRequestWriteAccessModal = (type:any)=>{
    if(type=="submit") {
      getRequestWriteAccess();
    }

  }
  return (
    <div className="initialOfferPage">
      {userRole == "charteringManagerRepresentative" && showNotification && <div className="representative-notification">
        <McNotification heading="Write access requested"
          body="Check the requests and manage access" onClick={openWriteAccessRequests}></McNotification>
      </div>}
      <InitiateOfferModal
        isModalOpen={isInitiateModal}
        onLoadData={modalData}
        onCanceled={cancelPopup}
      />
      <ExtendContractModal
        isModalOpen={isExtendModal}
        onLoadData={extendModalData}
        onCanceled={cancelExtendPopup}
      />
      {directNegtnModal && (
        <DirectIndication
          onLoadData={extendModalData}
          isSIRModal={directNegtnModal}
          charterId={extendModalData}
          closeFunc={closeDirectIndicationModal}
          extendContract={true}
        />
      )}

      <div
        className="ir-table-main table-margin2"
        data-testid="negotiation-table-cm"
      >
        <DataTable
          columns={columns}
          data={tableData}
          progressPending={isTableLoader}
          pagination
          paginationComponentOptions={paginationComponentOptions}
          progressComponent={<SkeletonLoader isLoader={true}></SkeletonLoader>}
        />
        {/*Old write access modal*/}
        {/* <WriteAccessModal
          isModalOpen={isWriteAccess}
          onCloseFunc={closeFunc}
          rowData={rowData}
          isCm={true}
          reloadPage={pageReload}
        /> */}
        
        <WriteAccessNewModal isModalOpen={isWriteAccess} onCloseFunc={closeFunc} infoData={brokerDetailsData} 
        individualAccessRequestsData={individualAccessRequestsData}
        requestWriteAccessModal={requestWriteAccess}
        disableRequestWriteAccessBtn={disableRequestWriteAccessBtn}/>
        <ManageWriteAccessRequestsModal isModalOpen={manageWriteRequestModal} 
        onModalClose={onWriteAccessRequestsModalClose} 
        accessRequestsData={accessRequestsData}
        userRole={userRole}
        setGiveAccessData={setGiveAccessData}
        />
        {/* <ManageAccessModal onModalOpen={isWriteAccessCMR}/> */}
        { isRequestWriteAccessModal && (<RequestWriteAccessModal 
      onModalOpen={isRequestWriteAccessModal} 
      onModalClose={closeRequestWriteAccessModal}
      />)}
      </div>
    </div>
  );
};

export default InitiateOfferTable;